import { faCheckCircle, faCircle, faExclamationCircle, faMinusCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, ListItemIcon, ListItemText, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IPatient } from '@ngt/forms-trials';
import { IMedicalReview, IMedicalReviewDefinition, IReviewer, MedicalReviewStatus } from '../../api/dtos';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { makeStyles } from '../../styles/makeStyles';

interface IMedicalReviewListReviewProps {
    patient?: IPatient;
    medicalReview: IMedicalReview;
    medicalReviewDefinition: IMedicalReviewDefinition;
    singleDefinition?: boolean;
    reviewers?: IReviewer[];
}

const useStyles = makeStyles<{singleDefinition?: boolean}>()((theme: Theme, {singleDefinition}) => ({
    item: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: -1,
        borderLeft: !!singleDefinition ? null : `1px solid ${theme.palette.grey[300]}`,
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    } as any,
    icon: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    text: {
        margin: 0,
        paddingLeft: theme.spacing(2)
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    titleMain: {
        marginRight: 'auto'
    },
    titleReviewer: {
    },
    titleDate: {
        lineHeight: '1'
    },
    new: {
        color: theme.palette.info.main
    },
    inProgress: {
        color: theme.palette.warning.main
    },
    complete: {
        color: theme.palette.success.main
    },
    cancelled: {
        color: theme.palette.grey[500]
    },
    unknown: {
        color: theme.palette.error.main
    }
}));


const MedicalReviewListReview: FunctionComponent<IMedicalReviewListReviewProps> = ({
    patient,
    medicalReview,
    singleDefinition,
    medicalReviewDefinition,
    reviewers
}) => {
    const {classes} = useStyles({ singleDefinition: singleDefinition });
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            item: {
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: '-1px',
                borderLeft: !!singleDefinition ? null : `1px solid ${theme.palette.grey[300]}`,
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            },
            icon: {
                borderRight: `1px solid ${theme.palette.grey[300]}`,
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
            },
            text: {
                margin: 0,
                paddingLeft: theme.spacing(2)
            },
            title: {
                display: 'flex',
                alignItems: 'center'
            },
            titleMain: {
                marginRight: 'auto'
            },
            titleReviewer: {
            },
            titleDate: {
                lineHeight: '1'
            },
            new: {
                color: theme.palette.info.main
            },
            inProgress: {
                color: theme.palette.warning.main
            },
            complete: {
                color: theme.palette.success.main
            },
            cancelled: {
                color: theme.palette.grey[500]
            },
            unknown: {
                color: theme.palette.error.main
            }
        }
    }, [theme, singleDefinition]);

    const medicalReviewContext = useContext(MedicalReviewExtensionContext);

    const title = useMemo(() => {
        if (singleDefinition) {
            return (
                <Box sx={styles.title}>
                    <Typography variant="subtitle1" sx={styles.titleMain}>
                        {medicalReviewDefinition?.name} &ndash; <Typography variant="subtitle2" component="span" sx={styles.titleReviewer}>{reviewers?.map(x => x.name)?.join(", ")}</Typography>
                    </Typography>
                    {
                        !!medicalReview?.startDate && (
                            <Typography variant="caption" sx={styles.titleDate}>
                                Date Started {DateTime.fromISO(medicalReview?.startDate).toFormat('dd/MM/yyyy')}{
                                    !!medicalReview?.updateDate && (
                                        <>, Date Updated {DateTime.fromISO(medicalReview?.updateDate).toFormat('dd/MM/yyyy')}</>
                                    )
                                }
                            </Typography>
                        )
                    }
                </Box>
            )
        }

        return (
            <div className={classes.title}>
                <Typography variant="subtitle1" sx={styles.titleMain}>
                    {reviewers?.map(x => x.name)?.join(", ")}
                </Typography>
                {
                    !!medicalReview?.startDate && (
                        <Typography variant="caption" sx={styles.titleDate}>
                            Date Started {DateTime.fromISO(medicalReview?.startDate).toFormat('dd/MM/yyyy')}{
                                !!medicalReview?.updateDate && (
                                    <>, Date Updated {DateTime.fromISO(medicalReview?.updateDate).toFormat('dd/MM/yyyy')}</>
                                )
                            }
                        </Typography>
                    )
                }
            </div>
        )
    }, [singleDefinition, medicalReviewDefinition, medicalReview, reviewers, classes])

    const icon = useMemo(() => {
        switch (medicalReview.status) {
            case MedicalReviewStatus.New:
                return <Tooltip title="New">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCircle} className={classes.new} size="2x" />
                    </div>
                </Tooltip>;
            case MedicalReviewStatus.InProgress:
                return <Tooltip title="In Progress">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faMinusCircle} className={classes.inProgress} size="2x" />
                    </div>
                </Tooltip>;
            case MedicalReviewStatus.Complete:
                return <Tooltip title="Complete">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCheckCircle} className={classes.complete} size="2x" />
                    </div>
                </Tooltip>;
            case MedicalReviewStatus.Cancelled:
                return <Tooltip title="Cancelled">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faTimesCircle} className={classes.cancelled} size="2x" />
                    </div>
                </Tooltip>;
            default:
                return <Tooltip title="Unknown">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faExclamationCircle} className={classes.unknown} size="2x" />
                    </div>
                </Tooltip>;
        }
    }, [medicalReview.status, classes])

    return (
        <ListItem
            sx={styles.item}
            button
            component={Link}
            to={medicalReviewContext?.createMedicalReviewRouteFn(patient!, medicalReview!)}
        >
            <ListItemIcon
                sx={styles.icon}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                sx={styles.text}
                primary={title}
            />
        </ListItem>
    );
};

export default MedicalReviewListReview;
