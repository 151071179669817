import { useContext, useMemo } from 'react';
import { SWRConfiguration } from 'swr';
import { FormDefinitionContext, FormByIdContext } from '@ngt/forms';
import { IMedicalReviewPatient, MedicalReviewPermission, PostHasPermissionsResponse } from '../../api/dtos';
import MedicalReviewContext from '../../contexts/data/MedicalReviewContext';
import usePermissions from './usePermissions';
import ReviewerContext from '../../contexts/data/ReviewerContext';
import { CoordinatingGroupContext, CountryContext, InstitutionContext, MasterGroupContext, PatientContext } from '@ngt/forms-trials';

const useContextPermissions = (
    permissions: MedicalReviewPermission[],
    configuration?: SWRConfiguration<PostHasPermissionsResponse, PostHasPermissionsResponse>
) => {
    const { data: masterGroup } = useContext(MasterGroupContext);
    const { data: coordinatingGroup } = useContext(CoordinatingGroupContext);
    const { data: country } = useContext(CountryContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: patient } = useContext(PatientContext);
    const { data: medicalReview } = useContext(MedicalReviewContext);
    const { data: formDefinition } = useContext(FormDefinitionContext);
    const { data: form } = useContext(FormByIdContext);
    const { data: reviewer } = useContext(ReviewerContext);

    const { data, error, loading, mutate } = usePermissions(
        permissions,
        masterGroup?.id,
        coordinatingGroup?.id,
        country?.id,
        institution?.id,
        patient?.id,
        (patient as IMedicalReviewPatient)?.medicalReviewers,
        medicalReview?.id,
        medicalReview?.reviewerIds,
        reviewer?.id,
        formDefinition?.id,
        form?.id,
        undefined,
        undefined,
        configuration
    );

    const result = useMemo(() => {
        return {
            data: permissions.map(p => data?.[p]),
            error,
            loading,
            mutate
        }
    }, [data, error, loading, mutate, ...permissions]); // eslint-disable-line react-hooks/exhaustive-deps

    return result;
}

export default useContextPermissions;