import { faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse, ListItem, ListItemIcon, ListItemText, Theme, useTheme } from '@mui/material';
import React, { ComponentType, FunctionComponent, useCallback, useMemo } from 'react';
import { IPatient } from '@ngt/forms-trials';
import { IMedicalReview, IMedicalReviewDefinition, IReviewer } from '../../api/dtos';
import MedicalReviewListReview from './MedicalReviewListReview';
import { makeStyles } from '../../styles/makeStyles';

interface IMedicalReviewListDefinitionProps {
    patient?: IPatient;
    expanded: boolean;
    toggleExpanded: (id: number) => void;
    medicalReviews: IMedicalReview[];
    medicalReviewDefinition: IMedicalReviewDefinition;
    action?: ComponentType<IMedicalReviewDefinitionActionProps>;
    singleDefinition?: boolean;
    reviewers: Record<number, IReviewer[] | undefined>;
}

export interface IMedicalReviewDefinitionActionProps {
    patient?: IPatient;
    medicalReviewDefinition: IMedicalReviewDefinition;
    medicalReviews: IMedicalReview[];
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        marginTop: -1
    },
    item: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    reviews: {
        paddingLeft: theme.spacing(6)
    }
}));


const MedicalReviewListDefinition: FunctionComponent<IMedicalReviewListDefinitionProps> = ({
    expanded,
    medicalReviewDefinition,
    medicalReviews,
    toggleExpanded,
    action,
    patient,
    singleDefinition,
    reviewers
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            container: {
                display: 'flex',
                marginTop: -1
            },
            item: {
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            },
            reviews: {
                paddingLeft: theme.spacing(6)
            }
        }
    }, [theme]);

    const onClick = useCallback(() => {
        toggleExpanded(medicalReviewDefinition.id!)

    }, [medicalReviewDefinition.id, toggleExpanded]);

    const ActionComponent = action;

    const reviews = medicalReviews?.map((medicalReview) => {
        return (
            <MedicalReviewListReview
                key={medicalReview.id}
                patient={patient}
                medicalReviewDefinition={medicalReviewDefinition}
                medicalReview={medicalReview}
                singleDefinition={singleDefinition}
                reviewers={reviewers[medicalReview.id!]}
            />
        );
    });

    if (singleDefinition) {
        return <>
            {!!medicalReviews?.length && reviews}
        </>;
    }

    return (
        <>
            <Box
                sx={styles.container}
            >
                <ListItem
                    button
                    sx={styles.item}
                    onClick={onClick}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon fixedWidth icon={expanded ? faChevronUp : faChevronDown} />
                    </ListItemIcon>
                    <ListItemText primary={<strong>{medicalReviewDefinition?.name}</strong>} />
                </ListItem>
                {
                    !!ActionComponent && !!medicalReviewDefinition && (
                        <ActionComponent
                            patient={patient}
                            medicalReviewDefinition={medicalReviewDefinition}
                            medicalReviews={medicalReviews}
                        />
                    )
                }
            </Box>
            <Collapse
                in={expanded}
            >
                <Box
                    sx={styles.reviews}
                >
                    {!!medicalReviewDefinition && !!medicalReviews?.length && reviews}
                </Box>
            </Collapse>
        </>
    );
};

export default MedicalReviewListDefinition;
