import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Box, List, Theme, useTheme } from '@mui/material';
import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { BasicAlert, IPatient } from '@ngt/forms-trials';
import { IMedicalReview, IMedicalReviewDefinition, IReviewer } from '../../api/dtos';
import MedicalReviewListDefinition, { IMedicalReviewDefinitionActionProps } from './MedicalReviewListDefinition';
import { makeStyles } from '../../styles/makeStyles';

interface IMedicalReviewListProps {
    patient?: IPatient;
    reviewers?: IReviewer[]
    medicalReviews?: IMedicalReview[];
    medicalReviewDefinitions?: IMedicalReviewDefinition[];
    actions?: Record<number, ComponentType<IMedicalReviewDefinitionActionProps>>;
}

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        padding: theme.spacing(2)
    },
}));


const MedicalReviewList: FunctionComponent<IMedicalReviewListProps> = ({
    medicalReviews,
    medicalReviewDefinitions,
    actions,
    patient,
    reviewers
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const [expanded, setExpanded] = useState<Record<number, boolean>>({})

    const combinedReviews = useMemo(() => {
        return medicalReviewDefinitions?.map(medicalReviewDefinition => {
            const reviews = medicalReviews?.filter(x => x.medicalReviewDefinitionId === medicalReviewDefinition.id) ?? [];

            if (reviews.length === 0) {
                return null;
            }

            return {
                definition: medicalReviewDefinition,
                reviews: reviews,
                action: actions?.[medicalReviewDefinition.id!],
                reviewers: reviews?.reduce((a: Record<number, IReviewer[] | undefined>, b: IMedicalReview) => {
                    a[b.id!] = reviewers?.filter(x => (b.reviewerId != null && b.reviewerId == x.id) || (b.reviewerId == null && b.reviewerIds?.includes(x.id!)));

                    return a;
                }, {}) ?? {}
            };
        }).filter(x => !!x) ?? [];

    }, [medicalReviews, medicalReviewDefinitions, actions, reviewers]);

    useEffect(() => {
        setExpanded(currentExpanded => {
            const newExpanded: Record<number, boolean> = {}

            medicalReviewDefinitions?.forEach(definition => {
                newExpanded[definition.id!] = currentExpanded[definition.id!] ?? true;
            })

            return newExpanded;
        })
    }, [medicalReviewDefinitions, setExpanded]);

    const toggleExpanded = useCallback((id: number) => {
        setExpanded(currentExpanded => ({
            ...currentExpanded,
            [id]: !currentExpanded[id]
        }))
    }, [setExpanded]);

    const noDefinitions = !combinedReviews?.length;
    const singleDefinition = combinedReviews?.length === 1;

    if (noDefinitions) {
        return (
            <Box sx={{padding: theme.spacing(2)}}>
                <BasicAlert
                    title="No Medical Reviews"
                    description="This patient has not had any medical reviews created."
                    icon={faExclamationTriangle}
                    severity="warning"
                />
            </Box>
        )
    }

    return (
        <List>
            {
                combinedReviews.map(combinedReview => {
                    if (!combinedReview) {
                        return null;
                    }

                    return (
                        <MedicalReviewListDefinition
                            key={combinedReview.definition?.id}
                            patient={patient}
                            expanded={expanded[combinedReview.definition?.id!]}
                            toggleExpanded={toggleExpanded}
                            medicalReviews={combinedReview.reviews ?? []}
                            medicalReviewDefinition={combinedReview.definition}
                            action={combinedReview.action}
                            singleDefinition={singleDefinition}
                            reviewers={combinedReview.reviewers}
                        />
                    )
                })
            }
        </List>
    );
};

export default MedicalReviewList;
